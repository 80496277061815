<script setup lang="ts">
// wraps base Select component for use within forms
// automatically handles validation, display of error messages, etc.
import { useField } from "vee-validate"
import Select from "@/components/V/Select.vue"

import type { SelectOption } from "@/components/V/Select.vue"

interface Props {
  name: string // primary key for field within the form
  showErrorMessage?: boolean
  // inherited from Select
  groupPosition?: "left" | "middle" | "right" | undefined
  description?: string | undefined // hint text
  disabled?: boolean
  label?: string | undefined
  optional?: boolean
  options?: SelectOption[]
  placeholder?: string
  size?: "small" | "medium" | "large"
  tooltip?: string | undefined // adds tooltip to description
}

const props = withDefaults(defineProps<Props>(), {
  showErrorMessage: true,
  // inherited from Select
  groupPosition: undefined,
  description: undefined,
  disabled: false,
  label: undefined,
  optional: false,
  options: () => [],
  placeholder: "",
  size: "medium",
  tooltip: undefined,
})

const emit = defineEmits(["update"])

// use name to look up field data in parent form schema
const { value, errorMessage } = useField<string>(() => props.name)
</script>

<template>
  <Select
    v-model="value"
    :group-position="groupPosition"
    :description="description"
    :disabled="disabled"
    :error-message="showErrorMessage ? errorMessage : ''"
    :invalid="!!errorMessage"
    :label="label"
    :optional="optional"
    :options="options"
    :placeholder="placeholder"
    :size="size"
    :tooltip="tooltip"
    @update="emit('update', $event)"
  />
</template>
